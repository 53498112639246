import React from 'react'
import { useNavigate } from 'react-router-dom'
import './PopUp.scss';
import book from '../../Images/book.png'
import bgnobgs from '../../Images/bgnobgs.png'
import closeMenu from '../../Images/closeMenu.png'

export default function PopUp({ setShowPopUp }) {
    const Navigate = useNavigate()
    return (
        <div className="PopUp">
            <div className="MainBanner">
                <div className="MainBannerHeader">
                    <img onClick={() => {
                        localStorage.setItem("showPopUp", true);
                        setShowPopUp(false)
                    }
                    } src={closeMenu} alt="Air Duct Cleaning In Redlands, CA | Air Duct Maintenance In Redlands, CA | AC Duct Cleaning In Redlands, CA | Dryer Vent Cleaning In Redlands, CA" />
                </div>
                <div className="MainBannerLeft">
                    <h1>Schedule Your Air Duct Service Today!</h1>
                    <h2>Don't wait until it's too late! Ensure your Air Duct is safe and functioning efficiently.</h2>
                    <a href="tel:951-499-1315" onClick={() => {
                        localStorage.setItem("showPopUp", true);
                        setShowPopUp(false)
                    }}>Click To Call!</a>
                    <a href="/book" onClick={() => {
                        localStorage.setItem("showPopUp", true);
                        setShowPopUp(false)
                    }}>SCHEDULE ONLINE<img src={book} alt="Air Duct Cleaning Near Me In Redlands, California" /></a>
                </div>
                <div className="MainBannerRight">
                    <img src={bgnobgs} alt="Air Duct Cleaning In Redlands, CA | Air Duct Maintenance In Redlands, CA | AC Duct Cleaning In Redlands, CA | Dryer Vent Cleaning In Redlands, CA" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
        </div>
    )
}
