import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import Banner from '../../Images/Banner.png'
import AreaData from '../../Components/ServicesPage/AreaData'

export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <img src={Banner} alt="Air Duct Cleaning In Redlands, CA | Air Duct Maintenance In Redlands, CA | AC Duct Cleaning In Redlands, CA | Dryer Vent Cleaning In Redlands, CA" />
                    <h1>AIR DUCT Redlands<b></b></h1>
                </div>
            </div>
            <div className="FooterTop">
                <div className="SiteMapDiv">
                    <h1>Redlands Air Duct Cleaning</h1>
                    <div className="SiteMapDivList">
                        <a href="/">Home Page</a>
                        <a href="/book">Schedule Online</a>
                        <a href="/contact">Contact Us</a>
                        <a href="/services">Our Services</a>
                        <a href="/blog">Blog</a>
                        <a href="/faq">Faqs</a>
                        <a href='/reviews'>Customer Reviews</a>
                        <a href="/sitemap">Site Map</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                            Redlands, California
                        </a>
                        <a target='_blank' href="tel:951-499-1315">
                            951-499-1315
                        </a>
                        <a target='_blank' href="mailto:info@Redlandshimneysweep.org">
                            info@Redlandshimneysweep.org
                        </a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Services</h1>
                    <div className="SiteMapDivList">
                        {AreaData.map((A, index) =>
                            <a href={A.Path} className="Header1BoxMenuBox">
                                {A.Title}
                            </a>
                        )}
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright 2024 Air Duct Redlands. All rights reserved. All available services, hours of operations, pricing structure, and guarantees may vary by location.</h1>
            </div>
        </div>
    )
}
